import {Reducer} from 'react';
import {StationDto, TrainDto} from 'src/services/models';
import {CoiCreateIncidentLocation} from './coiCreateIncidentLocation';
import {IncidentTypeDto} from 'src/services/models/incident-type-dto';
import {StationPresenceDto} from 'src/services/models/station-presence-dto';
import {AddressDetailsDto} from 'src/services/models/address-details-dto';
import {SaveIncidentAddressDto} from 'src/services/models/save-incident-address-dto';
import {LocationTypeDto} from 'src/services/models/location-type-dto';

/*
-----------------------------------------------------------------------
    Reducer for the CoiCreateIncidentInformation
    includes:
        - type
        - catalogId
        - subCatalogId
        - extraInfo
-----------------------------------------------------------------------
*/

export interface CoiCreateIncidentInformation {
  type: IncidentTypeDto;
  catalogId: string;
  subCatalogId: string;
  extraInfo: string;
}

export const intialCoiCreateIncidentInformation: CoiCreateIncidentInformation = {
  type: IncidentTypeDto.Incident,
  catalogId: '',
  subCatalogId: '',
  extraInfo: '',
};

export enum CoiCreateIncidentInformationActionTypes {
  TYPE = 'TYPE',
  CATALOG = 'catalog',
  SUBCATALOG = 'subcatalog',
  EXTRA_INFO = 'extra_info',
  RESET = 'reset',
}

export type CoiCreateIncidentInformationActions =
  | {type: CoiCreateIncidentInformationActionTypes.TYPE; payload: IncidentTypeDto}
  | {
      type:
        | CoiCreateIncidentInformationActionTypes.CATALOG
        | CoiCreateIncidentInformationActionTypes.SUBCATALOG
        | CoiCreateIncidentInformationActionTypes.EXTRA_INFO;
      payload: string;
    }
  | {type: CoiCreateIncidentInformationActionTypes.RESET};

export const CoiCreateIncidentInformationReducer: Reducer<
  CoiCreateIncidentInformation,
  CoiCreateIncidentInformationActions
> = (state, action) => {
  switch (action.type) {
    case CoiCreateIncidentInformationActionTypes.TYPE:
      return {...state, type: action.payload, catalogId: '', subCatalogId: ''};
    case CoiCreateIncidentInformationActionTypes.CATALOG:
      return {...state, catalogId: action.payload, subCatalogId: ''};
    case CoiCreateIncidentInformationActionTypes.SUBCATALOG:
      return {...state, subCatalogId: action.payload};
    case CoiCreateIncidentInformationActionTypes.EXTRA_INFO:
      return {...state, extraInfo: action.payload};
    case CoiCreateIncidentInformationActionTypes.RESET:
      return intialCoiCreateIncidentInformation;
  }
};

// -----------------------------------------------------------------------------------------------

/*
-----------------------------------------------------------------------
    Reducer for the CoiCreateIncidentLocation
    includes:
        - trains (can be multiple)
        - ptcar (only single one)
        - address
-----------------------------------------------------------------------
*/

export interface TrainWithMainLocationDto extends TrainDto {
  mainLocation: boolean;
  assignedCoi?: StationPresenceDto;
  assistants?: StationPresenceDto[];
}

export interface StationWithMainLocationDto extends StationDto {
  mainLocation: boolean;
  assignedCoi?: StationPresenceDto;
  assistants?: StationPresenceDto[];
}

export interface AddressWithMainLocationDto extends SaveIncidentAddressDto {
  assignedCoi: StationPresenceDto | undefined;
  assistants?: StationPresenceDto[];
}

export interface CoiCreateIncidentLocation {
  trains: TrainWithMainLocationDto[];
  ptcar: StationWithMainLocationDto | undefined;
  address: AddressWithMainLocationDto | undefined;
  comment: string | undefined;
}

export const initialCoiCreateIncidentLocation: CoiCreateIncidentLocation = {
  trains: [],
  ptcar: undefined,
  address: undefined,
  comment: undefined,
};

export enum CoiCreateIncidentLocationActionTypes {
  ADD_TRAINS = 'trains',
  ADD_PTCAR = 'ptcar',
  ADD_ADDRESS = 'address',
  CHANGE_ADDRESS = 'change_address',
  ADD_COMMENT = 'comment',
  REMOVE_TRAINS = 'r_trains',
  REMOVE_PTCAR = 'r_ptcar',
  REMOVE_ADDRESS = 'r_address',
  REMOVE_COMMENT = 'r_comment',
  CHANGE_MAIN_LOCATION = 'change_main_location',
  ADD_COI_TRAIN = 'ADD_COI_TRAIN',
  REMOVE_COI_TRAIN = 'REMOVE_COI_TRAIN',
  ADD_COI_PTCAR = 'ADD_COI_PTCAR',
  REMOVE_COI_PTCAR = 'REMOVE_COI_PTCAR',
  ADD_ASSIST_TRAIN = 'ADD_ASSIST_TRAIN',
  REMOVE_ASSIST_TRAIN = 'REMOVE_ASSIST_TRAIN',
  ADD_ASSIST_PTCAR = 'ADD_ASSIST_PTCAR',
  REMOVE_ASSIST_PTCAR = 'REMOVE_ASSIST_PTCAR',
  ADD_ASSIST_ADDRESS = 'ADD_ASSIST_ADDRESS',
  REMOVE_ASSIST_ADDRESS = 'REMOVE_ASSIST_ADDRESS',
  ADD_COI_ADDRESS = 'ADD_COI_ADDRESS',
  REMOVE_COI_ADDRESS = 'REMOVE_COI_ADDRESS',
  RESET = 'reset',
  ADD_ADDRESS_AND_COMMENT = 'ADD_ADDRESS_AND_COMMENT',
}

export type CoiCreateIncidentLocationActions =
  | {type: CoiCreateIncidentLocationActionTypes.ADD_TRAINS; payload: TrainDto}
  | {type: CoiCreateIncidentLocationActionTypes.ADD_PTCAR; payload: StationDto}
  | {type: CoiCreateIncidentLocationActionTypes.ADD_ADDRESS; payload: string | undefined}
  | {type: CoiCreateIncidentLocationActionTypes.ADD_COMMENT; payload: string}
  | {type: CoiCreateIncidentLocationActionTypes.REMOVE_TRAINS; payload: number}
  | {type: CoiCreateIncidentLocationActionTypes.REMOVE_PTCAR}
  | {type: CoiCreateIncidentLocationActionTypes.REMOVE_ADDRESS}
  | {type: CoiCreateIncidentLocationActionTypes.REMOVE_COMMENT}
  | {type: CoiCreateIncidentLocationActionTypes.CHANGE_MAIN_LOCATION; payload: number}
  | {type: CoiCreateIncidentLocationActionTypes.ADD_COI_TRAIN; payload: {pos: number; coi: StationPresenceDto}}
  | {type: CoiCreateIncidentLocationActionTypes.REMOVE_COI_TRAIN; payload: {pos: number}}
  | {type: CoiCreateIncidentLocationActionTypes.ADD_COI_PTCAR; payload: StationPresenceDto}
  | {type: CoiCreateIncidentLocationActionTypes.REMOVE_COI_PTCAR}
  | {type: CoiCreateIncidentLocationActionTypes.ADD_ASSIST_TRAIN; payload: {pos: number; id: StationPresenceDto}}
  | {type: CoiCreateIncidentLocationActionTypes.REMOVE_ASSIST_TRAIN; payload: {pos: number; id: string}}
  | {type: CoiCreateIncidentLocationActionTypes.ADD_ASSIST_PTCAR; payload: StationPresenceDto}
  | {type: CoiCreateIncidentLocationActionTypes.REMOVE_ASSIST_PTCAR; payload: string}
  | {type: CoiCreateIncidentLocationActionTypes.ADD_ASSIST_ADDRESS; payload: StationPresenceDto}
  | {type: CoiCreateIncidentLocationActionTypes.REMOVE_ASSIST_ADDRESS; payload: string}
  | {type: CoiCreateIncidentLocationActionTypes.ADD_COI_ADDRESS; payload: StationPresenceDto}
  | {type: CoiCreateIncidentLocationActionTypes.REMOVE_COI_ADDRESS}
  | {type: CoiCreateIncidentLocationActionTypes.ADD_ADDRESS_AND_COMMENT; payload: {address: string; comment: string}}
  | {type: CoiCreateIncidentLocationActionTypes.RESET}
  | {type: CoiCreateIncidentLocationActionTypes.CHANGE_ADDRESS; payload: string};

export const CoiCreateIncidentLocationReducer: Reducer<CoiCreateIncidentLocation, CoiCreateIncidentLocationActions> = (
  state,
  action,
) => {
  switch (action.type) {
    case CoiCreateIncidentLocationActionTypes.ADD_TRAINS:
      if (addLocationValidations.addTrain(state)) {
        const prevTrains = state.trains;
        let newTrain: TrainWithMainLocationDto = {mainLocation: false, assignedCoi: undefined, ...action.payload};
        if (prevTrains.length === 0) {
          newTrain.mainLocation = true;
        }
        prevTrains.push(newTrain);
        return {...state, trains: [...prevTrains]};
      }
      return state;
    case CoiCreateIncidentLocationActionTypes.ADD_PTCAR:
      if (addLocationValidations.addPtCar(state)) {
        return {...state, ptcar: {...action.payload, mainLocation: false}};
      }
      return state;
    case CoiCreateIncidentLocationActionTypes.ADD_ADDRESS:
      if (addLocationValidations.addAddress(state)) {
        return {
          ...state,
          address: {
            address: action.payload,
            mainLocation: true,
            type: LocationTypeDto.Address,
            assignedCoi: state.address?.assignedCoi ?? undefined,
          },
        };
      }
      return state;
    case CoiCreateIncidentLocationActionTypes.ADD_COMMENT:
      if (addLocationValidations.addComment(state)) {
        return {...state, comment: action.payload};
      }
      return state;
    case CoiCreateIncidentLocationActionTypes.REMOVE_TRAINS:
      if (removeLocationValidations.removeTrain(state)) {
        if (state.trains.length === 1) {
          return {
            ...state,
            trains: [],
            ptcar: undefined,
          };
        } else {
          const prevTrains = state.trains;
          const filter = prevTrains.filter(el => el.trainNumber !== action.payload);
          const hasMainLocation = filter.find(el => el.mainLocation);
          const hasPtCarWithMainLocation = state.ptcar;
          if (hasMainLocation || (!hasMainLocation && filter.length === 0) || hasPtCarWithMainLocation?.mainLocation) {
            return {...state, trains: [...filter]};
          }
          if (!hasMainLocation && filter.length > 0) {
            filter[0].mainLocation = true;
            return {...state, trains: [...filter]};
          }
          return {...state, trains: [...filter]};
        }
      }
      return state;
    case CoiCreateIncidentLocationActionTypes.REMOVE_PTCAR:
      if (removeLocationValidations.removePtCar(state)) {
        //if ptcar was main location
        const oldPtcar = state.ptcar;
        if (oldPtcar?.mainLocation) {
          const newTrains = state.trains;
          newTrains[0].mainLocation = true;
          return {...state, ptcar: initialCoiCreateIncidentLocation.ptcar, trains: newTrains};
        } else {
          return {...state, ptcar: initialCoiCreateIncidentLocation.ptcar};
        }
      }
      return state;
    case CoiCreateIncidentLocationActionTypes.REMOVE_ADDRESS:
      if (removeLocationValidations.removeAddress(state)) {
        return {...state, address: undefined};
      }
      return state;
    case CoiCreateIncidentLocationActionTypes.REMOVE_COMMENT:
      if (removeLocationValidations.removeAddress(state)) {
      }
      return state;
    case CoiCreateIncidentLocationActionTypes.CHANGE_MAIN_LOCATION:
      if (addLocationValidations.addMainLocation(state)) {
        if (action.payload === state.trains.length && state.ptcar) {
          const newTrains = state.trains.map(el => ({...el, mainLocation: false}));
          const newPtCar = state.ptcar;
          newPtCar.mainLocation = true;
          return {...state, trains: newTrains, ptcar: newPtCar};
        } else {
          const newTrains = state.trains.map(el => ({...el, mainLocation: false}));
          newTrains[action.payload].mainLocation = true;
          let newPtCar = state.ptcar;
          newPtCar ? (newPtCar.mainLocation = false) : (newPtCar = undefined);
          return {...state, trains: newTrains, ptcar: newPtCar};
        }
      }
      return state;
    case CoiCreateIncidentLocationActionTypes.ADD_COI_TRAIN:
      console.log('trigger');
      if (state.trains[action.payload.pos].assignedCoi === undefined) {
        const newTrains = state.trains;
        newTrains[action.payload.pos].assignedCoi = action.payload.coi;
        return {...state, trains: [...newTrains]};
      } else {
        const newTrains = state.trains;
        //@ts-ignore
        newTrains[action.payload.pos].assignedCoi = action.payload.coi;
        return {...state, trains: [...newTrains]};
      }
    case CoiCreateIncidentLocationActionTypes.REMOVE_COI_TRAIN:
      const newTrains = state.trains;
      newTrains[action.payload.pos].assignedCoi = undefined;
      return {...state, trains: [...newTrains]};
    case CoiCreateIncidentLocationActionTypes.ADD_COI_PTCAR:
      const newPtCars = state.ptcar;
      if (newPtCars === undefined) {
        return {...state};
      }

      if (newPtCars.assignedCoi === undefined) {
        newPtCars.assignedCoi = action.payload;
        return {...state, ptcar: {...newPtCars}};
      }
      return {...state};

    case CoiCreateIncidentLocationActionTypes.REMOVE_COI_PTCAR:
      if (state.ptcar === undefined) {
        return {...state};
      }

      if (state.ptcar.assignedCoi === undefined) {
        return {...state};
      }

      if (state.ptcar.assignedCoi) {
        const newPtCars = state.ptcar;
        if (newPtCars !== undefined && newPtCars.assignedCoi) {
          newPtCars.assignedCoi = undefined;
        }
        return {...state, ptcar: {...newPtCars}};
      }

      return {...state};

    case CoiCreateIncidentLocationActionTypes.ADD_ASSIST_TRAIN:
      if (state.trains) {
        const newTrains = state.trains;
        if (newTrains[action.payload.pos].assistants) {
          newTrains[action.payload.pos].assistants?.push(action.payload.id);
          return {...state, trains: [...newTrains]};
        }

        if (newTrains[action.payload.pos].assistants === undefined) {
          newTrains[action.payload.pos].assistants = [action.payload.id];
          return {...state, trains: [...newTrains]};
        }
      }
      return {...state};

    case CoiCreateIncidentLocationActionTypes.REMOVE_ASSIST_TRAIN:
      if (state.trains) {
        const newTrains = state.trains;
        if (newTrains[action.payload.pos].assistants) {
          const newAssitants = newTrains[action.payload.pos].assistants?.filter(
            ass => ass.employeeId !== action.payload.id,
          );
          newTrains[action.payload.pos].assistants = newAssitants;
          return {...state, trains: [...newTrains]};
        }

        if (newTrains[action.payload.pos].assistants === undefined) {
          return {...state};
        }
      }

      return {...state};

    case CoiCreateIncidentLocationActionTypes.ADD_ASSIST_PTCAR:
      if (state.ptcar) {
        const newPtCars = state.ptcar;
        if (newPtCars.assistants === undefined) {
          newPtCars.assistants = [];
        }
        newPtCars.assistants?.push(action.payload);
        return {...state, ptcar: {...newPtCars}};
      }

      return {...state};
    case CoiCreateIncidentLocationActionTypes.REMOVE_ASSIST_PTCAR:
      if (state.ptcar) {
        const newPtCar = state.ptcar;
        const newAssitants = newPtCar.assistants;
        const filteredAssistants = newAssitants?.filter(el => el.employeeId !== action.payload);
        if (filteredAssistants) {
          newPtCar.assistants = [...filteredAssistants];
          return {...state, ptcar: {...newPtCar}};
        }
      }
      return {...state};
    case CoiCreateIncidentLocationActionTypes.RESET:
      const newState = initialCoiCreateIncidentLocation;
      newState.trains = [];
      newState.address = undefined;
      return {...newState};
    case CoiCreateIncidentLocationActionTypes.ADD_ASSIST_ADDRESS:
      if (state.address && state.address.address && state.address.address?.length > 0) {
        const newAddress = state.address;
        //Check if assitant is already assigned
        const hasBeenAssigned = newAddress.assignments?.find(el => el === action.payload.employeeId);
        if (hasBeenAssigned) {
          return {...state};
        }
        newAddress.assignments?.push(action.payload.employeeId || '');
        if (newAddress.assistants && newAddress.assistants.length > 0) {
          newAddress.assistants.push(action.payload);
        } else {
          newAddress.assistants = [action.payload];
        }
        return {...state, address: {...newAddress}};
      }
      return {...state};
    case CoiCreateIncidentLocationActionTypes.REMOVE_ASSIST_ADDRESS:
      if (state.address && state.address.address && state.address.address.length > 0) {
        const newAddress = state.address;
        const newAssignments = newAddress.assignments?.filter(el => el != action.payload);
        const newAssistants = newAddress.assistants?.filter(el => el.employeeId != action.payload);
        newAddress.assignments = newAssignments;

        newAddress.assistants = newAssistants;
        return {...state, address: newAddress, comment: undefined};
      }
      return {...state};
    case CoiCreateIncidentLocationActionTypes.ADD_COI_ADDRESS:
      if (state.address && state.address.address && state.address.address.length > 0) {
        const newAddress = {...state.address, assignedCoi: action.payload};
        newAddress.employeeId = action.payload.employeeId;
        return {...state, address: newAddress};
      }
      return {...state};
    case CoiCreateIncidentLocationActionTypes.REMOVE_COI_ADDRESS:
      if (state.address && state.address.address && state.address.address.length > 0) {
        const newAddress = state.address;
        newAddress.employeeId = undefined;
        newAddress.assignedCoi = undefined;
        return {...state, address: newAddress};
      }
      return {...state};
    case CoiCreateIncidentLocationActionTypes.ADD_ADDRESS_AND_COMMENT:
      const newAddressAndCommentState = state;
      newAddressAndCommentState.address = {address: action.payload.address} as AddressWithMainLocationDto;
      newAddressAndCommentState.comment = action.payload.comment;
      return {...newAddressAndCommentState};
    case CoiCreateIncidentLocationActionTypes.CHANGE_ADDRESS:
      const newAddress = state;
      if (state.address) {
        state.address.address = action.payload;
      }
      return {...newAddress};
    default:
      return {...state};
  }
};

export const addLocationValidations = {
  addTrain: (state: CoiCreateIncidentLocation) => {
    const noAddress = state.address === undefined;
    return noAddress;
  },
  addPtCar: (state: CoiCreateIncidentLocation) => {
    const noAddress = state.address === undefined;
    const noPtCarYet = state.ptcar === undefined;
    const alreadyHasTrain = state.trains.length > 0;
    return noAddress && noPtCarYet && alreadyHasTrain;
  },
  addAddress: (state: CoiCreateIncidentLocation) => {
    const noPtCar = state.ptcar === undefined;
    const noAddressYet = state.address === undefined;
    const noTrains = state.trains.length === 0;
    return noPtCar && noAddressYet && noTrains;
  },
  addComment: (state: CoiCreateIncidentLocation) => {
    const noTrains = state.trains.length === 0;
    const noPtCar = state.ptcar === undefined;
    const address =
      state.address !== undefined &&
      state.address &&
      state.address.address !== undefined &&
      state.address.address !== null &&
      state.address.address.length > 0;
    return noTrains && noPtCar && address;
  },
  addMainLocation: (state: CoiCreateIncidentLocation) => {
    const needTrains = state.trains.length > 0;
    return needTrains;
  },
};

export const removeLocationValidations = {
  removeTrain: (state: CoiCreateIncidentLocation) => {
    return true;
  },
  removePtCar: (state: CoiCreateIncidentLocation) => {
    return true;
  },
  removeAddress: (state: CoiCreateIncidentLocation) => {
    return true;
  },
};
