import axios, {AxiosError} from 'axios';
import {FormState} from 'src/scenes/pmr/formState';
import {getActiveFormParams} from 'src/scenes/pmr/util';
import {DisabledReservationDto} from 'src/services/models/disabled-reservation-dto';
import {RefuseReservationActionDetailDto} from 'src/services/models/refuse-reservation-action-detail-dto';
import {ReservationActionDetailDto} from 'src/services/models/reservation-action-detail-dto';
import {ReservationActionResultDto} from 'src/services/models/reservation-action-result-dto';
import {ReservationDetailDto} from 'src/services/models/reservation-detail-dto';
import {ReservationStatusDto} from 'src/services/models/reservation-status-dto';

export const fetchReservations = async (
  baseUrl: string,
  status: Array<ReservationStatusDto>,
  district: string,
): Promise<DisabledReservationDto[]> => {
  let result: DisabledReservationDto[] = [];

  const regex = /,/g;

  if (district.length === 0) {
    return Promise.resolve([]);
  }

  try {
    const res = await axios.get(
      `${baseUrl}/api/reservations/disableds?${status
        .map((el, ind) => `${ind !== 0 ? '&' : ''}Statuses=${el}`)
        .toString()
        .replace(regex, '')}&District=${district}`,
    );
    result = res.data as DisabledReservationDto[];
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }

  return result;
};

export const acceptReservations = async (
  baseUrl: string,
  data: ReservationActionDetailDto,
): Promise<ReservationActionResultDto> => {
  try {
    const res: ReservationActionResultDto = await axios.post(`${baseUrl}/api/reservations/accept`, data);
    if (res) {
      return Promise.resolve(res);
    }
    return Promise.reject(false);
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

export const fetchSearchReservations = async (
  form: FormState,
  baseUrl: string,
  district: string,
  hasAssistanceFilters: boolean
): Promise<DisabledReservationDto[]> => {
  const appendString = getActiveFormParams(form, district, hasAssistanceFilters);
  const url = `${baseUrl}/api/reservations/disableds?${appendString}`;

  let result: DisabledReservationDto[] = [];

  try {
    const res = await axios.get(url);
    result = res.data as DisabledReservationDto[];
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }

  return result;
};

export const fetchReservationById = async (baseUrl: string, id: string): Promise<ReservationDetailDto> => {
  try {
    const res = await axios.get(`${baseUrl}/api/reservations/${id}`);
    return res.data as ReservationDetailDto;
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

export const refuseReservation = async (baseUrl: string, refusal: RefuseReservationActionDetailDto) => {
  try {
    const res = await axios.post(`${baseUrl}/api/reservations/refuse`, refusal);
    return res.data;
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

export const discardDraftReservation = async (baseUrl: string, reservationId: string) => {
  try {
    await axios.delete(`${baseUrl}/api/reservations/disableds/${reservationId}/draft`);
    return true;
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};
