import {Switch} from '@mui/material';
import {styled} from '@mui/system';
import GroupIcon from '@mui/icons-material/Group';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

import {Check, PeopleAltOutlined} from '@mui/icons-material';
import AssignIcon from 'src/icons/Assign';
import React from 'react';
import Supervisor from 'src/icons/Supervisor';
const StyledCoiFormButton = styled('button')({
  borderRadius: '4px',
  border: 'solid 1px rgba(0, 105, 180, 1)',
  fontSize: '16px',
  fontWeight: '700',
  color: 'rgba(0, 105, 180, 1)',
  background: 'transparent',
  '&:hover': {
    color: 'white',
    backgroundColor: 'rgba(0, 105, 180, 1)',
  },
  '&:active': {
    color: 'rgba(0, 105, 180, 1)',
    backgroundColor: 'transparent',
  },
  '&:disabled': {
    color: 'grey',
    border: 'solid 1px rgba(175, 175, 175, 0.87)',
    '&:hover': {
      color: 'grey',
      border: 'solid 1px rgba(175, 175, 175, 0.87)',
      backgroundColor: 'transparent',
    },
  },
});

export const CoiFormButton: React.FC<React.JSX.IntrinsicElements['button']> = props => {
  return <StyledCoiFormButton {...props}>{props.children}</StyledCoiFormButton>;
};

export const IOSSwitch = styled<typeof Switch>(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({theme}) => ({
  width: 52,
  height: 32,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 4,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',

      '& + .MuiSwitch-track': {
        backgroundColor: 'rgba(240, 244, 248, 1)',
        border: 'solid 1px rgba(0, 105, 180, 1)',
        opacity: 1,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: 'rgba(0, 105, 180, 1)',
      },
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>')`,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    backgroundColor: 'rgba(119, 119, 119, 1)',
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: -2,
      top: -2,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 26,
    backgroundColor: 'rgba(215, 215, 215, 1)',
    opacity: 1,
    //@ts-ignore
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const StyledCoiAssignCoiButton = styled('button')({
  borderRadius: '8px',
  border: 'solid 1px #000000',
  fontSize: '16px',
  fontWeight: '700',
  backgroundColor: 'white',
  display: 'flex',
  alignItems: 'center', // Align items vertically in the center
  justifyContent: 'center', // Center content horizontally
  padding: '8px 16px', // Adjust padding for consistent spacing
  gap: '8px', // Space between icon and text
});

export const CoiAssignCoiButton: React.FC<React.JSX.IntrinsicElements['button'] & {isCoi?: boolean}> = props => {
  if (props.isCoi) {
    return (
      <StyledCoiAssignCoiButton
        {...props}
        sx={{
          backgroundColor: '#0069B4',
          color: 'white',
          border: 'none',
        }}
      >
        <Check style={{color: 'white'}} />
        {props.children}
      </StyledCoiAssignCoiButton>
    );
  }

  return (
    <StyledCoiAssignCoiButton
      {...props}
      sx={{
        backgroundColor: props.isCoi ? '#0069B4' : 'white',
        '&:disabled': {backgroundColor: props.isCoi ? '#0069B4' : 'lightgray', color: props.isCoi ? 'white' : 'black'},
      }}
      disabled={props.disabled}
    >
      {props.isCoi ? <Check style={{color: 'white'}} /> : <AssignIcon />}
      {props.children}
    </StyledCoiAssignCoiButton>
  );
};

export const AssignButton: React.FC<React.JSX.IntrinsicElements['button'] & {selected?: boolean}> = props => {
  return (
    <StyledCoiAssignCoiButton
      {...props}
      sx={{
        backgroundColor: props.selected ? '#0069B4' : 'white',
        '&:disabled': {
          backgroundColor: props.selected ? '#0069B4' : 'lightgray',
          color: props.selected ? 'white' : 'black',
        },
      }}
      disabled={props.disabled}
    >
      {props.selected ? <Check style={{color: 'white'}} /> : <Supervisor />}
      {props.children}
    </StyledCoiAssignCoiButton>
  );
};

export const CoiAssignAssistButon: React.FC<
  React.JSX.IntrinsicElements['button'] & {isAssistant?: boolean}
> = props => {
  if (props.isAssistant) {
    return (
      <StyledCoiAssignCoiButton
        {...props}
        sx={{
          backgroundColor: '#0069B4',
          color: 'white',
          border: 'none',
        }}
      >
        <Check style={{color: 'white'}} />
        {props.children}
      </StyledCoiAssignCoiButton>
    );
  }
  return (
    <StyledCoiAssignCoiButton
      {...props}
      sx={{
        backgroundColor: props.isAssistant ? '#0069B4' : 'white',
        '&:disabled': {
          backgroundColor: props.isAssistant ? '#0069B4' : 'lightgray',
          color: props.isAssistant ? 'white' : 'black',
        },
      }}
      disabled={props.disabled}
    >
      {props.isAssistant ? <Check style={{color: 'white'}} /> : <PeopleAltOutlinedIcon />}
      {props.children}
    </StyledCoiAssignCoiButton>
  );
};
