import React from 'react';
import {getTimeDifferenceFromEpochValues} from 'src/utils/getTimeDifferenceFromEpochValues';
import {EnvironmentContext} from '../environment/environment.store';
import {getReferenceData} from './cache.service';

//Network error handling
import {NetworkContext} from '../network/network.store';

//Loader

import {CircularProgress} from '@mui/material';
import {ReasonDto} from 'src/services/models';
import {getCachedData, setToLocal} from './cache.util';
import {AuthContext} from '../authentication/store.auth';
import {TaxiDto} from 'src/services/models/taxi-dto';

const DAY_IN_MILLI = 86400000 / 2;

interface ICacheContext {
  reasons: object;
  districts: object;
  stations: any[];
  disabilities: object;
  ageCategories: object;
  isCachePopulated: boolean;
  reservationRefusalReasons: ReasonDto[];
  taxiCompanies: TaxiDto[];
}

const CacheContext = React.createContext<ICacheContext>({
  reasons: {},
  districts: {},
  stations: [],
  disabilities: {},
  ageCategories: {},
  isCachePopulated: false,
  reservationRefusalReasons: [],
  taxiCompanies: [],
});

const CacheProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [reasons, setReasons] = React.useState({});
  const [districts, setDistricts] = React.useState({});
  const [stations, setStations] = React.useState([{}]);
  const [disabilities, setDisabilities] = React.useState({});
  const [ageCategories, setAgeCategories] = React.useState({});
  const [isCachePopulated, setIsCachePopulated] = React.useState(false);
  const [reservationRefusalReasons, setReservationRefusalReasons] = React.useState<ReasonDto[]>([]);
  const [taxiCompanies, setTaxiCompanies] = React.useState<TaxiDto[]>([]);

  const {validateNetworkCall} = React.useContext(NetworkContext);

  const {variables} = React.useContext(EnvironmentContext);
  const {validUser} = React.useContext(AuthContext);

  const CACHE_STATE = {
    reasons,
    districts,
    stations,
    disabilities,
    ageCategories,
    isCachePopulated,
    reservationRefusalReasons,
    taxiCompanies,
  };

  const getAndCacheData = async () => {
    await getReferenceData(variables?.BASE_ENDPOINT).then(res => {
      if (res.length > 0) {
        setToLocal(res);
        getCachedData(
          setReasons,
          setDistricts,
          setStations,
          setDisabilities,
          setAgeCategories,
          setReservationRefusalReasons,
          setTaxiCompanies,
        );

        setIsCachePopulated(true);
      } else {
        setToLocal(Array.from(Array(5)).map(_el => ({})));
        //@ts-ignore
        validateNetworkCall(res.response.code, res.response.data);
        setIsCachePopulated(true);
        throw new Error('Not able to get endpoints');
      }
    });
  };

  const intializer = (fromLogin?: boolean) => {
    const cachedTimeInEpoch = parseInt(localStorage.getItem('CACHE_LAST_UPDATED') || '0');
    const currentTimeInEpoch = new Date().getTime();

    //Handle if there is no last cache date in storage
    if (cachedTimeInEpoch === 0) {
      //Collect data as there will be no cache
      getAndCacheData();
      setIsCachePopulated(false);
      localStorage.setItem('CACHE_LAST_UPDATED', currentTimeInEpoch.toString());

      return;
    }

    //Check age of previous cache
    const timeDifference = getTimeDifferenceFromEpochValues({time1: currentTimeInEpoch, time2: cachedTimeInEpoch});

    if (fromLogin) {
      getAndCacheData();
      setIsCachePopulated(false);
    } else {
      if (timeDifference >= DAY_IN_MILLI) {
        getAndCacheData();
        setIsCachePopulated(false);
        localStorage.setItem('CACHE_LAST_UPDATED', currentTimeInEpoch.toString());
        return;
      }
    }
    getCachedData(
      setReasons,
      setDistricts,
      setStations,
      setDisabilities,
      setAgeCategories,
      setReservationRefusalReasons,
      setTaxiCompanies,
    );
    setIsCachePopulated(true);
  };

  React.useEffect(() => {
    if (validUser) {
      intializer(true);
    }
  }, [validUser]);

  return (
    <CacheContext.Provider value={CACHE_STATE}>
      {isCachePopulated ? (
        children
      ) : (
        <div style={{display: 'grid', width: '100vw', height: '100vh', placeItems: 'center'}}>
          <CircularProgress data-testid={'store_loader'} variant="indeterminate" />
        </div>
      )}
    </CacheContext.Provider>
  );
};

export default CacheProvider;
export {CacheContext};
