import React from 'react';
import { useTranslation } from 'react-i18next';

import {CoiOverviewStore} from '../../../coi.list';
import {Box, IconButton, Paper, Typography} from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment';
import {localStorageKeys} from 'src/utils/localStorageKeys';
import {CoiIncidentListItemCard} from './coiIncidentListCard';

export const CoiIncidentList: React.FC<CoiOverviewStore & { handleSelectIncident: (id: string) => void }> = ({ handleSelectIncident, ...store }) => {
  return (
    <Box
      sx={{
        padding: '20px 15px',
        backgroundColor: '#EFF4F9',
        maxHeight: 'calc(100vh - 64px)',
        width: '380px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <DayStepper {...store} handleSelectIncident={handleSelectIncident} />
      {store.incidents && (
        <Box
          sx={{
            display: 'flex',
            maxHeight: 'calc(100vh - 64px)',
            overflowY: 'auto',
            flexDirection: 'column',
            scrollbarWidth: '10px',
          }}
        >
          {store.incidents.items?.map((item) => (
            <CoiIncidentListItemCard
              key={item.id}
              incident={item}
              onSelectIncident={() => item.id && handleSelectIncident(item.id)}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

const DayStepper: React.FC<CoiOverviewStore & { handleSelectIncident: (id: string) => void }> = ({handleSelectIncident, ...store}) => {
  const { t } = useTranslation();
  const district = localStorage.getItem(localStorageKeys.SELECTED_DISTRICT) || '';

  const handleClick = (dir: 'backwards' | 'forwards') => {
    handleSelectIncident('');
    const newDate = store.incidentListController.changeDate(dir);
    store.incidentListController.refetchData(newDate, district, null);
  };

  return (
    <Paper elevation={1} sx={{
      minHeight: '64px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '24px',
      padding: '0 8px',
      width: 'fill-available',
    }}>
      <IconButton onClick={() => handleClick('backwards')}>
        <ArrowBackIosIcon />
      </IconButton>
      <Typography sx={{fontWeight: 'bold'}}>
        {store.incidentListController.isToday() ? t('coi.incident-today') : moment(store.date).format('DD/MM')}
      </Typography>
      <IconButton disabled={store.incidentListController.isToday()} onClick={() => handleClick('forwards')}>
        <ArrowForwardIosIcon />
      </IconButton>
    </Paper>
  );
};
