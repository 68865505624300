//@ts-nocheck
import {Box, Drawer, Button} from '@mui/material';
import moment from 'moment';
import React from 'react';
import {AlternativeTravelDto} from 'src/services/models/alternative-travel-dto';
import {DisabledOriginalJourneyDto} from 'src/services/models/disabled-original-journey-dto';
import {ChangeRouteContext} from '../ChangeRoute.store';
import {OriginalRouteStep} from '../OriginalRouteView/OriginalRouteStep';
import {originalRouteStepMapper} from '../util/OriginalRouteStepMap';
import CloseIcon from '@mui/icons-material/Close';
import {TransportTypeDto} from 'src/services/models/transport-type-dto';
import {TaxiProposal} from '../NewRoutePlanner/TaxiProposal';
import {useTranslation} from 'react-i18next';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {SettingsContext} from 'src/context/settings/settings.store';
import {useParams, useLocation, Navigate} from 'react-router-dom';

export const ProposalSelectDrawer = ({
  proposal,
  openDrawer,
  onCloseDrawer,
}: {
  proposal: AlternativeTravelDto;
  openDrawer: boolean;
  onCloseDrawer: () => void;
}) => {
  const {handleSelectionOfNewRoute} = React.useContext(ChangeRouteContext);

  const [isValidRoute, setIsValidRoute] = React.useState(true);
  const {t} = useTranslation();

  return (
    <Drawer
      open={openDrawer}
      anchor="right"
      onClose={() => {
        setIsValidRoute(true);
        onCloseDrawer();
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          padding: '25px',
          paddingBottom: '0px',
          backgroundColor: 'white',
        }}
      >
        <h4>New proposed route</h4>
        <p style={{textAlign: 'right'}}>
          {proposal.duration},{' '}
          {proposal.journeys && proposal.journeys.length > 1 ? proposal.journeys && proposal.journeys.length - 1 : ''}{' '}
          {proposal.journeys && proposal.journeys.length === 1
            ? t('cr.no-transfers')
            : proposal.journeys && proposal.journeys.length === 2
            ? t('cr.transfer')
            : t('cr.transfers')}
        </p>
      </Box>
      {proposal.journeys?.map((el, ind, arr) => (
        <Box
          key={`${el.transportId}+${ind}`}
          sx={{
            backgroundColor: 'white',
            padding: '25px',
            paddingTop: '0px',
            paddingBottom: '10px',
            marginBottom: ind === arr.length - 1 ? '10px' : '0px',
            position: 'relative',
            zIndex: 0,
          }}
        >
          {el.transportType === TransportTypeDto.Taxi ? (
            <TaxiProposal
              onProposal={true}
              //@ts-ignore
              arrival={el.arrivalName || ''}
              //@ts-ignore
              departureComment={el.departureRemark}
              //@ts-ignore
              departureName={el.departureName || ''}
              taxiCompany={''}
              //@ts-ignore
              travelTime={{timeInSeconds: parseInt(el.duration)} || ''}
              arrivalTime={moment(el.arrivalTime || '').format('HH:mm')}
              departureTime={moment(el.departureTime || '').format('HH:mm')}
              withDelete={undefined}
              ind={ind}
              arrivalComment={el.arrivalRemark || ''}
            />
          ) : (
            <OriginalRouteStep
              route={originalRouteStepMapper(el as DisabledOriginalJourneyDto)}
              isSelected={false}
              handleClick={() => {}}
              ind={ind}
              withHover={false}
              withDelete={undefined}
            />
          )}
        </Box>
      ))}
      <ProposalSelectInvalidRoute proposal={proposal} setIsValidRoute={setIsValidRoute} />
      <Box sx={{display: 'flex', justifyContent: 'flex-end', padding: '25px'}}>
        <Button variant="text" onClick={onCloseDrawer}>
          {t('filter-section.filter-cancel')}
        </Button>
        <Button
          variant="contained"
          disabled={!isValidRoute}
          onClick={() => {
            handleSelectionOfNewRoute(proposal);
            onCloseDrawer();
            setIsValidRoute(true);
          }}
        >
          Confirm
        </Button>
      </Box>
    </Drawer>
  );
};

const ProposalSelectInvalidRoute = ({
  proposal,
  setIsValidRoute,
}: {
  proposal: AlternativeTravelDto;
  setIsValidRoute: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const locations = useLocation();

  const assistanceType = () => {
    const queryStringWithoutQuestionMark = locations.search.substring(1);
    // Split the query string into key-value pairs
    const keyValuePairs = queryStringWithoutQuestionMark.split('&');
    // Initialize an empty array to store the values
    const values: string[] = [];
    // Loop through each key-value pair
    keyValuePairs.forEach((pair: string) => {
      // Split the pair into key and value
      const [key, value] = pair.split('=');
      // Decode the URI component to handle special characters properly
      const decodedValue = decodeURIComponent(value);
      // Push the value into the array
      values.push(decodedValue);
    });
    return values[2];
  };
  //Only check if departure is minimum of 15 minutes after arrival
  const {selectedOriginalRoute, isIndividualSelected, selectedIndividualProposal, selectedNewProposal, tripStatus} =
    React.useContext(ChangeRouteContext);

  const {
    RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME,
    RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME,
  } = React.useContext(SettingsContext);

  if (!isIndividualSelected || selectedIndividualProposal === null) {
    if (
      (tripStatus.active === 'inbound'
        ? selectedNewProposal?.inboundProposal
        : selectedNewProposal?.outBoundProposal) &&
      selectedNewProposal &&
      (tripStatus.active === 'inbound'
        ? selectedNewProposal.inboundProposal?.journeys
        : selectedNewProposal.outBoundProposal?.journeys) &&
      proposal.journeys
    ) {
      const inOrOut =
        tripStatus.active === 'inbound' ? selectedNewProposal.inboundProposal : selectedNewProposal.outBoundProposal;
      if (inOrOut && inOrOut.journeys) {
        const prevRoute = inOrOut?.journeys[inOrOut?.journeys?.length - 1] as DisabledOriginalJourneyDto;
        const proposedRoute = proposal.journeys[inOrOut?.journeys?.length];
        const prevRouteArrival = prevRoute?.arrivalTime;
        const proposedDeparture = proposedRoute && proposedRoute.departureTime;

        const minTransfer =
          assistanceType() === 'Full'
            ? RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME
            : RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME;

        const isValid = moment(prevRouteArrival).add(minTransfer, 'm').isBefore(moment(proposedDeparture));
        const isSameValid = moment(prevRouteArrival).add(minTransfer, 'm').isSame(moment(proposedDeparture));
        const departureBeforeArrival = moment(prevRouteArrival).isBefore(moment(proposedDeparture));
        const isSameTime = moment(prevRouteArrival).isSame(moment(proposedDeparture));
        console.log(isSameValid);
        if (!departureBeforeArrival && !isSameValid) {
          setIsValidRoute(false);
        }

        if (isSameTime) {
          setIsValidRoute(false);
          return null;
        }

        if (isSameValid) {
          setIsValidRoute(true);
          return null;
        }

        if (!isValid) {
          return <InvalidProposalSign />;
        }
        return null;
      }
    }
    return null;
  }

  if (
    selectedIndividualProposal === 0 &&
    !(tripStatus.active === 'inbound' ? selectedNewProposal?.inboundProposal : selectedNewProposal?.outBoundProposal)
  ) {
    setIsValidRoute(true);
    return null;
  } else {
    let prevRoute: DisabledOriginalJourneyDto | undefined | null =
      selectedOriginalRoute && selectedOriginalRoute[selectedIndividualProposal - 1];
    let proposedRoute = proposal.journeys && proposal.journeys[selectedIndividualProposal];
    const inOrOut =
      tripStatus.active === 'inbound' ? selectedNewProposal?.inboundProposal : selectedNewProposal?.outBoundProposal;
    if (
      (tripStatus.active === 'inbound'
        ? selectedNewProposal?.inboundProposal
        : selectedNewProposal?.outBoundProposal) &&
      selectedNewProposal &&
      inOrOut?.journeys
    ) {
      prevRoute = inOrOut?.journeys[inOrOut?.journeys?.length - 1] as DisabledOriginalJourneyDto;
      proposedRoute = proposal.journeys && proposal.journeys[inOrOut?.journeys?.length];
    }

    const prevRouteArrival = prevRoute?.arrivalTime;
    const proposedDeparture = proposedRoute && proposedRoute.departureTime;

    const isValid = moment(prevRouteArrival).add(14, 'm').isBefore(moment(proposedDeparture));

    if (!isValid) {
      setIsValidRoute(false);
      return <InvalidProposalSign />;
    }
    return null;
  }
};

const InvalidProposalSign = () => {
  const {t} = useTranslation();
  return (
    <Box sx={{width: '100%', padding: '0px 25px'}}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '40px 1fr',
          gap: '15px',
          padding: '10px',
          height: '60px',
          margin: '0px auto',
          border: 'solid 1px rgba(209, 209, 209, 1)',
          borderRadius: '5px',
        }}
      >
        <Box sx={{display: 'grid', placeItems: 'center', backgroundColor: 'rgba(187, 0, 0, 0.08)'}}>
          <CloseIcon sx={{width: '14px', height: '14px', color: 'rgba(187, 0, 0, 1)'}} />
        </Box>
        <Box sx={{display: 'grid', alignItems: 'center'}}>
          <p style={{margin: 0, color: 'rgba(187, 0, 0, 1)', fontWeight: 'bold'}}>{t('cr.transfer-time')}</p>
        </Box>
      </Box>
    </Box>
  );
};
