import React from 'react';
import {Box} from '@mui/system';
import {MenuContainer, TopLevelContainer, SubLevelButton, SubLevelContainer} from './Menu.styles';

import MenuComponents from './components';

//Routes
import {routes} from 'src/constants/routes';
import {Button, Divider, IconButton, MenuItem, Select, Typography} from '@mui/material';
import AssistIcon from 'src/icons/AssistIcon';
import ColleagueIcon from 'src/icons/ColleagueIcon';
import Folder from 'src/components/icons/Folder';
import ClockIcon from 'src/icons/ClockIcon';
import CoiIcon from 'src/icons/CoiIcon';
import {PowerSettingsNew, SettingsOutlined} from '@mui/icons-material';
import {useNavigate, useLocation} from 'react-router-dom';
import {useAutoRedirect} from 'src/hooks/useAutoRedirect';
import {AuthContext, AuthPermissions} from 'src/context/authentication/store.auth';
import {SettingsContext} from 'src/context/settings/settings.store';
import {useTranslation} from 'react-i18next';
import {localStorageKeys} from 'src/utils/localStorageKeys';
import {ReservationsContext} from 'src/context/reservations/reservations.store';
import {MissionContext} from '../coordinator/MissionOverview/missions.store';
import {EnvironmentContext} from 'src/context/environment/environment.store';

type ITopLevelRouting = {
  routes: typeof routes;
  handleChange: (topLevelRoute: string) => void;
  toplevel: string;
};

type ISubLevelRouting = {
  routes: Object;
  toplevel: string;
  handleRouteChange: (route: string) => void;
  requiresExtraInformation: (route: string) => void;
  isMenuOpen: boolean;
};

export const Menu = () => {
  const [selectedSubRoutes, setSelectedSubRoutes] = React.useState(routes.missions);
  const [topLevelRoute, setTopLevelRoute] = React.useState(Object.keys(routes)[2]);

  const {permissions} = React.useContext(AuthContext);

  //Handle menu open state
  const {isMenuOpen, setIsMenuOpen} = React.useContext(SettingsContext);

  const {filteredMissions} = React.useContext(MissionContext);
  const navigate = useNavigate();
  const location = useLocation();
  //@ts-ignore
  const notAssignList = filteredMissions.filter(el => el.owner === null || el.owner === undefined);
  //@ts-ignore
  const assignList = filteredMissions.filter(el => el.owner !== null && el.owner !== undefined);

  //Amout of reservations
  const {handleLengthCalc} = React.useContext(ReservationsContext);

  const {setPrevRoute} = React.useContext(SettingsContext);

  useAutoRedirect(routes, routes.missions.overview);

  const requiresExtraInformation = (route: string) => {
    if (route === routes.missions.overview) {
      return notAssignList.length > 0 ? (
        <MenuComponents.BadgeItem amount={notAssignList.length} isSelected={location.pathname.includes(route)} />
      ) : (
        ''
      );
    }

    if (route === routes.missions.assign) {
      return assignList.length > 0 ? (
        <MenuComponents.BadgeItem amount={assignList.length} isSelected={location.pathname.includes(route)} />
      ) : (
        ''
      );
    }

    if (route === routes.pmr.overview) {
      return handleLengthCalc.revLength > 0 ? (
        <MenuComponents.BadgeItem amount={handleLengthCalc.revLength} isSelected={location.pathname.includes(route)} />
      ) : (
        ''
      );
    }

    if (route === routes.pmr.in_progress) {
      return handleLengthCalc.revProgLength > 0 ? (
        <MenuComponents.BadgeItem
          amount={handleLengthCalc.revProgLength}
          isSelected={location.pathname.includes(route)}
        />
      ) : (
        ''
      );
    }
  };

  React.useEffect(() => {
    setIsMenuOpen(true);
    let index = 2;
    const routesAsMap = Object.values(routes).map(el => Object.values(el));
    if (location.pathname.includes('trip-details')) {
      return;
    }
    routesAsMap.forEach((routeCollection, ind) => {
      if (routeCollection.includes(location.pathname.replace('/', ''))) {
        index = ind;
      }
    });
    setTopLevelRoute(Object.keys(routes)[index]);
    //@ts-ignore
    setSelectedSubRoutes(routes[Object.keys(routes)[index]]);

    if (location.pathname.includes('change-route')) {
      setTopLevelRoute(routes.pmr.overview);
      setIsMenuOpen(false);
    }

    if (location.pathname.includes('create-incident')) {
      setTopLevelRoute(Object.keys(routes)[3]);
      //@ts-ignore
      setSelectedSubRoutes(routes[Object.keys(routes)[3]]);
      setIsMenuOpen(false);
    }
  }, [location]);

  const handleTopLevelRouteChange = (newTopLevelRoute: string) => {
    if (newTopLevelRoute === topLevelRoute) {
      return null;
    }

    setIsMenuOpen(true);

    //@ts-ignore
    setSelectedSubRoutes(routes[newTopLevelRoute]);
    setTopLevelRoute(newTopLevelRoute);

    //@ts-ignore
    const correctRoutes: string[] = Object.values(routes[newTopLevelRoute]);
    if (correctRoutes[0] === routes.settings.support) {
      handleRouteChange(correctRoutes[1] || '');
    } else {
      if (newTopLevelRoute === 'pmr' && !permissions?.includes(AuthPermissions.ApprovalReservations)) {
        handleRouteChange(correctRoutes[2] || '');
      } else {
        handleRouteChange(correctRoutes[0] || '');
      }
    }
  };

  const handleRouteChange = (route: string) => {
    setPrevRoute(location.pathname);
    navigate(`/${route}`);
  };
  return (
    <Box
      sx={{
        ...MenuContainer,
        gridTemplateColumns: isMenuOpen ? '100px 260px' : '100px 0px',
        width: isMenuOpen ? '360px' : '100px',
      }}
    >
      <TopLevelRouting routes={routes} handleChange={handleTopLevelRouteChange} toplevel={topLevelRoute} />
      {isMenuOpen && (
        <Box sx={SubLevelContainer}>
          <SubLevelRouting
            isMenuOpen={isMenuOpen}
            routes={selectedSubRoutes}
            toplevel={topLevelRoute}
            handleRouteChange={handleRouteChange}
            requiresExtraInformation={requiresExtraInformation}
          />
        </Box>
      )}
    </Box>
  );
};

const TopLevelRouting: React.FC<ITopLevelRouting> = ({routes, handleChange, toplevel}) => {
  const {logOut, permissions} = React.useContext(AuthContext);
  const {variables} = React.useContext(EnvironmentContext);

  const {COI_FEATURE_FLAG} = React.useContext(SettingsContext);

  return (
    <Box sx={TopLevelContainer}>
      <MenuComponents.NewMissionButton />
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <IconButton
            sx={{
              borderRadius: '16px',
              backgroundColor: toplevel.includes(Object.keys(routes)[0]) ? '#EFF4F9' : 'none',
            }}
            onClick={() => {
              handleChange(Object.keys(routes)[0]);
            }}
          >
            <AssistIcon />
          </IconButton>
          <MenuComponents.ButtonLabel text="PMR" />
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <IconButton
            sx={{borderRadius: '16px', backgroundColor: toplevel.includes(Object.keys(routes)[1]) ? '#EFF4F9' : 'none'}}
            onClick={() => handleChange(Object.keys(routes)[1])}
          >
            <ColleagueIcon />
          </IconButton>
          <MenuComponents.ButtonLabel text="GROBO" />
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <IconButton
            sx={{borderRadius: '16px', backgroundColor: toplevel.includes(Object.keys(routes)[2]) ? '#EFF4F9' : 'none'}}
            onClick={() => handleChange(Object.keys(routes)[2])}
          >
            <Folder />
          </IconButton>
          <MenuComponents.ButtonLabel text="Missions" />
        </Box>

        {permissions?.includes(AuthPermissions.CoiModule) ? (
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <IconButton
              disabled={!COI_FEATURE_FLAG}
              sx={{
                borderRadius: '16px',
                backgroundColor: toplevel.includes(Object.keys(routes)[3]) ? '#EFF4F9' : 'none',
              }}
              onClick={() => handleChange(Object.keys(routes)[3])}
              disableFocusRipple={!COI_FEATURE_FLAG}
            >
              <CoiIcon disabled={!COI_FEATURE_FLAG} />
            </IconButton>
            <MenuComponents.ButtonLabel text="COI" disabled={!COI_FEATURE_FLAG} />
          </Box>
        ) : null}

        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <IconButton
            sx={{borderRadius: '16px', backgroundColor: toplevel.includes(Object.keys(routes)[4]) ? '#EFF4F9' : 'none'}}
            onClick={() => handleChange(Object.keys(routes)[4])}
          >
            <ClockIcon />
          </IconButton>
          <MenuComponents.ButtonLabel text="Check-in" />
        </Box>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
        <IconButton
          sx={{borderRadius: '16px', backgroundColor: toplevel.includes(Object.keys(routes)[4]) ? '#EFF4F9' : 'none'}}
          onClick={() => handleChange(Object.keys(routes)[5])}
        >
          <SettingsOutlined />
        </IconButton>
        <Divider sx={{backgroundColor: 'black', color: 'black'}} />
        <IconButton onClick={() => logOut()}>
          <PowerSettingsNew />
        </IconButton>
        <Divider sx={{backgroundColor: 'black', color: 'black'}} />
        {variables.DICOS_VERSION}
      </Box>
    </Box>
  );
};

const SubLevelRouting: React.FC<ISubLevelRouting> = ({
  routes,
  toplevel,
  handleRouteChange,
  requiresExtraInformation,
  isMenuOpen,
}) => {
  const {permissions} = React.useContext(AuthContext);
  const location = useLocation();
  const {i18n, t} = useTranslation();

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem(localStorageKeys.LANGUAGE, lang);
  };

  if (isMenuOpen) {
    return (
      <Box sx={{width: '80%'}}>
        <Typography sx={{fontWeight: 'bold', fontSize: '16px', paddingLeft: '15px', marginBottom: '15px'}}>
          {toplevel.toLocaleUpperCase()}
        </Typography>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '15px'}}>
          {Object.values(routes).map((route: string) => {
            const isPmr = route.includes('last-minute-reservation-pmr');
            const isGrobo = route.includes('last-minute-reservation-grobo');
            const isChangeRoute = route.includes('change-route');
            const isLanguageSelector = route.includes('language');
            const isCreateIncident = route.includes('create-incident');

            const backGroundColor = `${
              isPmr ? '#FFD046' : isGrobo ? '#00A8A9' : location.pathname.includes(route) ? '#EFF4F9' : 'none'
            }`;

            if (isChangeRoute || isCreateIncident) {
              return null;
            }

            //For language route we will not redirect but give a list of languages
            if (isLanguageSelector) {
              return (
                <Select
                  sx={{border: 'none', outline: 'none', paddingLeft: '15px', marginTop: '8px'}}
                  value={i18n.language}
                  variant="standard"
                  disableUnderline={true}
                  onChange={e => {
                    handleLanguageChange(e.target.value);
                  }}
                >
                  <MenuItem value="Nl">{t('choose-language.language-nl')}</MenuItem>
                  <MenuItem value="En">{t('choose-language.language-en')}</MenuItem>
                  <MenuItem value="Fr">{t('choose-language.language-fr')}</MenuItem>
                </Select>
              );
            }

            if (!permissions?.includes(AuthPermissions.PrintStickeringMissions) && route.includes('print-stickers')) {
              return null;
            }

            if (!permissions?.includes(AuthPermissions.ApprovalReservations) && (route.includes('pmr/overview') || route.includes('pmr/validation-in-progress'))) {
              return null;
            }

            return (
              <Button
                key={route as string}
                sx={{
                  ...SubLevelButton,
                  backgroundColor: backGroundColor,
                }}
                onClick={() => handleRouteChange(route)}
              >
                {(route as string).split('/')[1].replaceAll('-', ' ')} {requiresExtraInformation(route as string)}
              </Button>
            );
          })}
        </Box>
      </Box>
    );
  } else {
    return null;
  }
};
