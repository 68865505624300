import {useTranslation} from 'react-i18next';
import {CoiCreateIncidentLocationProps} from '../coiCreateIncidentLocation';
import {CoiAssignCoiModal, CoiTargetType, TargetType} from '../coiAssignModal';
import React from 'react';
import {TrainListElement} from './TrainList';
import {PtCarElement} from './PtCar';
import {StationPresenceDto} from 'src/services/models/station-presence-dto';
import {CoiCreateIncidentLocationActionTypes} from '../reducer';

export const TrainAndPtCarOverview = (props: CoiCreateIncidentLocationProps) => {
  const {t} = useTranslation();

  const [coiTarget, setCoiTarget] = React.useState<CoiTargetType | undefined>(undefined);
  const [isCoiModalVisible, setIsCoiModalVisble] = React.useState(false);
  const [assignIndex, setAssigIndex] = React.useState<number | undefined>(undefined);

  const handleCoiAssignModalTarget = (target: CoiTargetType | undefined, targetIndex: number | undefined) => {
    if (target === undefined) {
      setCoiTarget(undefined);
      setIsCoiModalVisble(false);
      setAssigIndex(targetIndex);
    } else {
      setCoiTarget(target);
      setIsCoiModalVisble(true);
      setAssigIndex(targetIndex);
    }
  };

  const assignCoi = (employee: StationPresenceDto, index?: number) => {
    console.log(employee);
    console.log(coiTarget);
    if (employee === undefined && coiTarget !== undefined) {
      throw new Error('Employee is undefined');
    }
    if (coiTarget?.type === TargetType.TRAIN && index !== null && index !== undefined) {
      props.coiCreateIncidentLocationDispatch({
        type: CoiCreateIncidentLocationActionTypes.ADD_COI_TRAIN,
        payload: {coi: employee, pos: index},
      });
    } else if (coiTarget?.type === TargetType.PT_CAR) {
      props.coiCreateIncidentLocationDispatch({
        type: CoiCreateIncidentLocationActionTypes.ADD_COI_PTCAR,
        payload: employee,
      });
    }
  };

  const unAssignCoi = (index?: number) => {
    if (coiTarget?.type === TargetType.TRAIN && index !== null && index !== undefined) {
      props.coiCreateIncidentLocationDispatch({
        type: CoiCreateIncidentLocationActionTypes.REMOVE_COI_TRAIN,
        payload: {pos: index},
      });
    } else if (coiTarget?.type === TargetType.PT_CAR) {
      props.coiCreateIncidentLocationDispatch({
        type: CoiCreateIncidentLocationActionTypes.REMOVE_COI_PTCAR,
      });
    }
  };

  const assignAssist = (assist: StationPresenceDto, index?: number) => {
    console.log(assist);
    console.log(index);
    if (assist === undefined) {
      throw new Error('Employee is undefined');
    }
    if (coiTarget?.type === TargetType.TRAIN && index !== null && index !== undefined) {
      props.coiCreateIncidentLocationDispatch({
        type: CoiCreateIncidentLocationActionTypes.ADD_ASSIST_TRAIN,
        payload: {id: assist, pos: index},
      });
    } else if (coiTarget?.type === TargetType.PT_CAR) {
      props.coiCreateIncidentLocationDispatch({
        type: CoiCreateIncidentLocationActionTypes.ADD_ASSIST_PTCAR,
        payload: assist,
      });
    }
  };

  const unAssignAssist = (id: string, index?: number) => {
    if (coiTarget?.type === TargetType.TRAIN && index !== null && index !== undefined) {
      props.coiCreateIncidentLocationDispatch({
        type: CoiCreateIncidentLocationActionTypes.REMOVE_ASSIST_TRAIN,
        payload: {pos: index, id: id},
      });
    } else if (coiTarget?.type === TargetType.PT_CAR) {
      props.coiCreateIncidentLocationDispatch({
        type: CoiCreateIncidentLocationActionTypes.REMOVE_ASSIST_PTCAR,
        payload: id,
      });
    }
  };

  return (
    <div style={{padding: '24px'}}>
      {coiTarget !== undefined && (
        <CoiAssignCoiModal
          ptcar={props.coiCreateIncidentLocationState.ptcar}
          trains={props.coiCreateIncidentLocationState.trains}
          assignCoi={assignCoi}
          assignAssist={assignAssist}
          unAssignAssist={unAssignAssist}
          unAssignCoi={unAssignCoi}
          assignIndex={assignIndex}
          target={coiTarget}
          open={isCoiModalVisible && coiTarget !== undefined}
          onClose={() => handleCoiAssignModalTarget(undefined, undefined)}
        />
      )}
      <p style={{margin: '0', fontWeight: 'bold', fontSize: '22px', marginBottom: '12px'}}>
        {props.coiCreateIncidentLocationState.trains.length > 1 ? t('coi.multiple-trains') : t('coi.trains')}
      </p>
      <div
        style={{
          padding: '10px',
          paddingLeft: '15px',
          display: 'grid',
          gridTemplateColumns: 'repeat(2,15%) repeat(2, 160px) auto',
          gap: '10px',
        }}
      >
        <span style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{t('coi.main-incident')}</span>
        <span style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{t('reservation.form.train.label')}</span>
        <span style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{t('coor-role.assignModal.from')}</span>
        <span style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{t('coor-role.assignModal.to')}</span>
      </div>
      <div style={{display: 'grid', gap: '16px'}}>
        {props.coiCreateIncidentLocationState.trains.map((el, ind) => (
          <TrainListElement
            props={props}
            train={el}
            key={ind}
            ind={ind}
            handleCoiAssignModalTarget={handleCoiAssignModalTarget}
          />
        ))}
      </div>
      {props.coiCreateIncidentLocationState.ptcar && (
        <>
          <p style={{margin: '0', fontWeight: 'bold', fontSize: '22px', marginBottom: '12px', marginTop: '24px'}}>
            {t('coi.ptcar')}
          </p>
          <div
            style={{
              padding: '10px',
              display: 'grid',
              paddingLeft: '15px',
              gridTemplateColumns: 'repeat(2,15%) repeat(2, 160px) auto auto',
              gap: '10px',
            }}
          >
            <span style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{t('coi.main-incident')}</span>
            <span style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{t('coi.location')}</span>
            <span style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{t('coi.total-tracks')}</span>
          </div>
          <PtCarElement props={props} handleCoiAssignModalTarget={handleCoiAssignModalTarget} />
        </>
      )}
    </div>
  );
};
