import moment from 'moment';
import {SubPmrRoutes} from './Pmr';
import {FormState} from './formState';
import {DisabledJourneyDto} from 'src/services/models/disabled-journey-dto';
import {DisabledReservationDto} from 'src/services/models/disabled-reservation-dto';
import {localStorageKeys} from 'src/utils/localStorageKeys';
import {DisabledTravelDto} from 'src/services/models/disabled-travel-dto';

export const getCorrectTitle = (variant: SubPmrRoutes): string => {
  switch (variant) {
    case SubPmrRoutes.OVERVIEW:
      return 'New Reservations';
    case SubPmrRoutes.SEARCH:
      return 'Search for Reservations';
    case SubPmrRoutes.VALIDATION:
      return 'Reservations In Validation';
    default:
      return 'New Reservations';
  }
};

const doProgressCalculation = (travel: DisabledTravelDto) => {
  let amountOfConfirmations = 0;
  let totalAmount = 0;
  travel.journeys?.forEach(j => {
    if (j.isArrivalOk || !j.withArrivalAssistance) {
      amountOfConfirmations++;
    }

    if (j.isDepartureOk || !j.withDepartureAssistance) {
      amountOfConfirmations++;
    }

    totalAmount = totalAmount + 2;
  });

  return 100 * (amountOfConfirmations / totalAmount);
};

//Rules of appearance

//-----------------------------------------------------

//HIDE RULE: 1
//if all stations that need assistance are selected (completly accepted reservation)
//Use the progress calculation

//-----------------------------------------------------

//HIDE RULE
//if none of the stations are part of the district we are in

//-----------------------------------------------------

//-----------------------------------------------------

//HIDE RULE
//if all stations that are part of the district are approved

//-----------------------------------------------------

export const finalizedHideIfCompliedToSelection = (
  item: JSX.Element,
  reservation: DisabledReservationDto,
): null | JSX.Element => {
  //Get all stations that are part of the current district
  const district = localStorage.getItem(localStorageKeys.SELECED_STATIONS)?.split(',') || '';

  let allJourneyPartOfTheDistrict: Array<boolean | null | undefined> = [];

  reservation.travels?.forEach(l => {
    const partOf: Array<boolean | null | undefined> = [];
    l.journeys?.forEach(journey => {
      if (district.includes(journey.departureUicCode || '') && journey.withDepartureAssistance) {
        return partOf.push(journey.isDepartureOk);
      }

      if (district.includes(journey.arrivalUicCode || '') && journey.withArrivalAssistance) {
        return partOf.push(journey.isArrivalOk);
      }
    });

    allJourneyPartOfTheDistrict = [...allJourneyPartOfTheDistrict, ...partOf];
  });

  if (allJourneyPartOfTheDistrict.length === 0) {
    return null;
  }

  //Check if they require assistance if they are NOT accepted yet
  const arrAllAccepted = allJourneyPartOfTheDistrict.every(el => el === true);

  if (arrAllAccepted) {
    return null;
  }

  return item;
};

export const isStationPartOfDistrict = (stations: string, stationId: string | number) => {
  const stationsArray = stations.split(',').map(el => el.replace(',', ''));
  if (stationsArray.includes(stationId.toString())) {
    return true;
  }
  return false;
};

const appendStatuses = (form: FormState, p: string[]) => {
  if (form.status.length > 0) {
    let statuses = '';
    form.status.forEach((status, ind) => {
      statuses = statuses + `Statuses=${status}` + (ind !== form.status.length - 1 ? '&' : '');
    });
    p.push(statuses);
  }

  return p;
};

const createAppendString = (appendString: string, p: string[]) => {
  p.forEach((el, ind) => {
    if (ind === p.length - 1) {
      appendString = appendString.concat(`${el}`);
    } else {
      appendString = appendString.concat(`${el}&`);
    }
  });

  return appendString;
};

const appendDates = (form: FormState, p: string[]) => {
  if (form.departureDate) {
    p.push(`DateFrom=${moment(form.departureDate).format('yyyy-MM-DD')}`);
  } else {
    p.push(`DateFrom=${moment().format('yyyy-MM-DD')}`);
  }

  if (form.arrivalDate) {
    p.push(`DateTo=${moment(form.arrivalDate).format('yyyy-MM-DD')}`);
  } else {
    p.push(`DateFrom=${moment().format('yyyy-MM-DD')}`);
  }
  return p;
};

export const getActiveFormParams = (form: FormState, district: string, hasAssistanceFilters: boolean): string => {
  let toAppend: string[] = [`District=${district}`];
  if (form.reservation) {
    let appendString;
    if (form.reservation.includes('N°')) {
      appendString = `&Id=${form.reservation.replace('N°', '').replaceAll(' ', '')}`;
    } else {
      appendString = `&Id=${form.reservation}`;
    }
    return appendString;
  }

  toAppend = appendDates(form, toAppend);
  if (form.departure && form.station) {
    toAppend.push(`DepartureStation=${form.station}`);
  }

  if (form.arrival && form.station) {
    toAppend.push(`ArrivalStation=${form.station}`);
  }

  toAppend = appendStatuses(form, toAppend);

  if (form.firstName) {
    toAppend.push(`ClientFirstName=${form.firstName}`);
  }

  if (form.lastName) {
    toAppend.push(`ClientLastName=${form.lastName}`);
  }

  if (form.email) {
    toAppend.push(`ClientEmail=${form.email}`);
  }

  if (form.train) {
    toAppend.push(`TrainNumber=${form.train}`);
  }

  if (hasAssistanceFilters && form.assistance && form.assistance !== 'all') {
    toAppend.push(`AssistanceType=${form.assistance}`);
  }

  let appendString = '';

  return createAppendString(appendString, toAppend);
};

//Custom Events

export const CUSTOM_EVENTS = {
  force_recalculation_line_height: 'force-recalculation-line-height',
};
export const FORCE_RECALCULATION_LINE_HEIGHT = new Event(CUSTOM_EVENTS.force_recalculation_line_height);
