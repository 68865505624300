/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {BrowserRouter, Outlet, Route, Routes} from 'react-router-dom';
import TelemetryProvider from 'src/telemetry-provider';
import {getAppInsights} from 'src/TelemetryService';

import './App.css';
import {EnvironmentContext} from './context/environment/environment.store';
import { AuthContext, AuthPermissions } from './context/authentication/store.auth';

//Routing
import {ReservationTypeDto} from 'src/services/models';

import CreateMissionNew from 'src/scenes/Pages/CreateMissionNew';

import MissionOverview from './scenes/coordinator/MissionOverview/MissionOverview';
import {OverviewCheckIn} from './scenes/coordinator/OverviewCheckIn/OverviewCheckIn';
import PrintStickersView from './scenes/coordinator/PrintStickers';
import {Reservation} from './scenes/coordinator/Reservation/Reservation';
import PrintReservationDetails from './scenes/coordinator/ReservationDetails/PrintMissionReservationDetails';
import GroupReservationDetails from './scenes/coordinator/ReservationDetails/GroupMissionReservationDetails';
import {Legend} from './scenes/coordinator/legend';
import {TripDetailsNew} from './scenes/coordinator/TripDetailsNew/TripDetailsNew';
import Pmr, {SubPmrRoutes} from 'src/scenes/pmr/Pmr';
import Settings from 'src/scenes/settings';
import {ChangeRoute} from 'src/scenes/change-route/ChangeRoute';
import {routes} from './constants/routes';
import CheckIn from './scenes/coordinator/CheckIn/CheckIn';
import {Main} from './scenes/coordinator/Home/Home.styles';
import moment from 'moment';
import {SearchMissionsDto} from './services/models/search-missions-dto';
import getMissions from './services/GET/getMissions';
import {Menu} from 'src/scenes/menu/Menu';
import SidebarMenu from 'src/components/coordinator/SidebarMenu/SidebarMenu';
import TopBar from 'src/components/coordinator/TopBar/TopBar';

import Box from '@mui/material/Box';
import {SettingsContext} from './context/settings/settings.store';
import CoiCreateIncident from 'src/scenes/coi/coi.createIncident';
import CoiOverview from './scenes/coi/coi.list';

function App() {
  const {variables} = React.useContext(EnvironmentContext);
  const {isMenuOpen} = React.useContext(SettingsContext);
  const {permissions} = React.useContext(AuthContext);

  //Routes
  const missionOverViewRoute = variables.USE_PMR_3 ? routes.missions.overview : 'mission-overview';
  const missionAssignRoute = variables.USE_PMR_3 ? routes.missions.assign : 'mission-assign';
  const checkInRoute = variables.USE_PMR_3 ? routes.checkin.my : 'checkin';
  const checkInOverviewRoute = variables.USE_PMR_3 ? routes.checkin.overview : 'checkin-overview';
  const groupMissionRoute = variables.USE_PMR_3 ? routes.grobo.group : 'reservation';
  const printMissionRoute = variables.USE_PMR_3 ? routes.grobo.print : 'print-stickers';
  const lastMinuteReservationPmrRoute = variables.USE_PMR_3
    ? 'reservation/last-minute-reservation-pmr'
    : 'last-minute-reservation-pmr';
  const lastMinuteReservationGroboRoute = variables.USE_PMR_3
    ? 'reservation/last-minute-reservation-grobo'
    : 'last-minute-reservation-grobo';
  const tripDetailsRoute = 'trip-details/:id';
  const legendRoute = variables.USE_PMR_3 ? routes.settings.legend : 'legend';
  const date = moment(new Date()).format('yyyy-MM-DD');

  const handleStationFilter = (props: SearchMissionsDto) => {
    if (props.stationIds) getMissions(variables.BASE_ENDPOINT, props);
  };

  const columnLayout = variables.USE_PMR_3
    ? isMenuOpen
      ? '360px 1fr'
      : '100px 1fr'
    : isMenuOpen
    ? '240px 1fr'
    : '100px 1fr';

  const isAbleToPrint = permissions?.includes(AuthPermissions.PrintStickeringMissions);
  const isCoiAvailable = permissions?.includes(AuthPermissions.CoiModule);
  const isReservationAvailable = permissions?.includes(AuthPermissions.ApprovalReservations);

  return (
    <BrowserRouter>
      <TelemetryProvider
        //@ts-ignore
        instrumentationKey={variables.INSIGHTS_KEY}
        after={() => {
          /* appInsights = */ getAppInsights();
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: columnLayout,
            gridTemplateRows: '64px 1fr',
          }}
        >
          <TopBar fetchData={handleStationFilter} date={date} dateTo={date} />
          {variables.USE_PMR_3 ? <Menu /> : <SidebarMenu />}
          <Main sx={{overflow: 'hidden'}}>
            <Routes>
              <Route
                path={`/${missionOverViewRoute}`}
                element={<MissionOverview isNotAssignScreen={true} isAssignScreen={false} />}
              />
              <Route
                path={`/${missionAssignRoute}`}
                element={<MissionOverview isAssignScreen={true} isNotAssignScreen={false} />}
              />
              <Route path={`/${checkInRoute}`} element={<CheckIn />} />
              <Route path={`/${checkInOverviewRoute}`} element={<OverviewCheckIn />} />
              <Route path={`/${groupMissionRoute}`} element={<Reservation />} />
              {isAbleToPrint ? <Route path={`/${printMissionRoute}`} element={<PrintStickersView />} /> : null}
              <Route path={`/details/stickering/:id`} element={<PrintReservationDetails />} />
              <Route path={`/details/group/:id`} element={<GroupReservationDetails />} />

              <Route
                path={`/${lastMinuteReservationPmrRoute}`}
                element={<CreateMissionNew variant={ReservationTypeDto.Disabled} />}
              />
              <Route
                path={`/${lastMinuteReservationGroboRoute}`}
                element={<CreateMissionNew variant={ReservationTypeDto.Group} />}
              />
              <Route path={`/${tripDetailsRoute}`} element={<TripDetailsNew />} />
              <Route path={`/${legendRoute}`} element={<Legend />} />
              <Route path={`/${routes.settings.support}`} element={<Settings.support />} />
              {isReservationAvailable ? <Route path={`/${routes.pmr.overview}`} element={<Pmr subRoute={SubPmrRoutes.OVERVIEW} />} /> : null}
              {isReservationAvailable ? <Route path={`/${routes.pmr.in_progress}`} element={<Pmr subRoute={SubPmrRoutes.VALIDATION} />} /> : null}
              <Route path={`/${routes.pmr.search}`} element={<Pmr subRoute={SubPmrRoutes.SEARCH} />} />
              <Route path={`/${routes.pmr.changeRoute}`} element={<ChangeRoute />} />
              {isCoiAvailable ? <Route path={`/${routes.coi.create_incident}`} element={<CoiCreateIncident />} /> : null}
              {isCoiAvailable ? <Route path={`/${routes.coi.overview}`} element={<CoiOverview isFollowing={false} />} /> : null}
              {isCoiAvailable ? <Route path={`/${routes.coi.follow_up}`} element={<CoiOverview isFollowing={true} />} /> : null}
            </Routes>
          </Main>
        </Box>
      </TelemetryProvider>
    </BrowserRouter>
  );
}

export default App;
