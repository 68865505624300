import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CacheContext} from 'src/context/cache/cache.store';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {NetworkContext} from 'src/context/network/network.store';
import {DistrictDto, LanguageDto, LocalizedTextDto, StationDto} from 'src/services/models';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import {PresenceOrigin, SearchPrecensesQuery} from './AssignModal';
import {OrganizationUnitDto} from 'src/services/models/organization-unit-dto';
import {OrganizationUnitTypeDto} from 'src/services/models/organization-unit-type-dto';

export enum Variant {
  BTP = 'BPT',
  BCS = 'BCS',
}
export const AssignSap: React.FC<{
  handleSearch: (props: SearchPrecensesQuery) => Promise<void>;
  loading: boolean;
  units: OrganizationUnitDto[];
}> = ({handleSearch, loading, units}) => {
  const {i18n, t} = useTranslation();

  const [selectedOrganizationalUnit, setSelectedOrganizationalUnit] = React.useState<OrganizationUnitDto | undefined>(
    undefined,
  );
  const [variant, setVariant] = React.useState<Variant>(Variant.BTP);

  // Reset organizational unit when variant changes
  React.useEffect(() => {
    setSelectedOrganizationalUnit(undefined);
  }, [variant]);

  return (
    <Box sx={{border: 'solid 1px lightgrey', padding: '25px'}}>
      <Stack>
        <Box>
          <RadioGroup
            row
            value={variant}
            onChange={e => {
              setVariant(e.target.value as Variant);
            }}
          >
            <FormControlLabel value={Variant.BTP} control={<Radio />} label={t('pmr-mission.btp')} />
            <FormControlLabel value={Variant.BCS} control={<Radio />} label={t('pmr-mission.bcs')} />
          </RadioGroup>
        </Box>
        <Typography variant="h5" sx={{marginTop: '15px', marginBottom: '15px'}}>
          {t('pmr-mission.select-district')}
        </Typography>
        {variant === Variant.BTP ? (
          <BPT
            unit={selectedOrganizationalUnit}
            setUnit={setSelectedOrganizationalUnit}
            units={units}
            loading={loading}
            search={handleSearch}
          />
        ) : (
          <BCS
            unit={selectedOrganizationalUnit}
            setUnit={setSelectedOrganizationalUnit}
            units={units}
            loading={loading}
            search={handleSearch}
          />
        )}
      </Stack>
    </Box>
  );
};

const BPT: React.FC<{
  unit: OrganizationUnitDto | undefined;
  units: OrganizationUnitDto[];
  setUnit: React.Dispatch<React.SetStateAction<OrganizationUnitDto | undefined>>;
  loading: boolean;
  search: (props: SearchPrecensesQuery) => Promise<void>;
}> = ({setUnit, unit, units, loading, search}) => {
  const {t} = useTranslation();

  return (
    <Box sx={{display: 'grid', gridTemplateColumns: '1fr 20%', gap: '15px', marginBottom: '15px'}}>
      <FormControl>
        <InputLabel id="sap-placeholder">{t('pmr-mission.sap-placeholder')}</InputLabel>
        <Select
          labelId="sap-placeholder"
          label={t('pmr-mission.sap-placeholder')}
          value={unit?.id || ''}
          onChange={e => {
            const selectedUnit = units.find(el => el.id === e.target.value);
            setUnit(selectedUnit);
          }}
        >
          {units
            .filter(el => el.type === OrganizationUnitTypeDto.Bpt2)
            .map(el => (
              <MenuItem key={el.id} value={el.id || ''}>
                {el.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Button
        variant="outlined"
        disabled={unit === undefined || loading}
        onClick={() => unit && search({origin: PresenceOrigin.SAP, payload: unit})}
      >
        {t('pmr.search.search')}
      </Button>
    </Box>
  );
};

const BCS: React.FC<{
  unit: OrganizationUnitDto | undefined;
  units: OrganizationUnitDto[];
  setUnit: React.Dispatch<React.SetStateAction<OrganizationUnitDto | undefined>>;
  loading: boolean;
  search: (props: SearchPrecensesQuery) => Promise<void>;
}> = ({setUnit, unit, units, loading, search}) => {
  const {t} = useTranslation();

  return (
    <Box sx={{display: 'grid', gridTemplateColumns: '1fr 20%', gap: '15px', marginBottom: '15px'}}>
      <FormControl>
        <InputLabel id="sap-placeholder">{t('pmr-mission.sap-placeholder')}</InputLabel>
        <Select
          labelId="sap-placeholder"
          label={t('pmr-mission.sap-placeholder')}
          value={unit?.id || ''}
          onChange={e => {
            const selectedUnit = units.find(el => el.id === e.target.value);
            setUnit(selectedUnit);
          }}
        >
          {units
            .filter(el => el.type === OrganizationUnitTypeDto.Bcs)
            .map(el => (
              <MenuItem key={el.id} value={el.id || ''}>
                {el.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Button
        variant="outlined"
        disabled={unit === undefined || loading}
        onClick={() => unit && search({origin: PresenceOrigin.SAP, payload: unit})}
      >
        {t('pmr.search.search')}
      </Button>
    </Box>
  );
};
